import { useContext } from 'react';
import ServicesContext from '../../global/services/ServicesContext';
import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { mutateDocumentsKeys } from './mutateKeyFactory';
import { LockUnlockDocumentsInfoRequestDto } from '../../Model/requests/LockUnlockDocumentsInfoRequestDto';
import { LockUnlockResponseDto } from '../../Model/responseDtos/LockUnlockResponseDto';
import { searchKeys } from '../search/queryKeyFactory';
import { useSearchContext } from '../search/SearchContext';

export function useUnlockDocuments(): UseMutationResult<
  LockUnlockResponseDto,
  unknown,
  { dto: LockUnlockDocumentsInfoRequestDto }
> {
  const servicesCtx = useContext(ServicesContext);
  const queryClient = useQueryClient();
  const { queryRequest } = useSearchContext();

  const unlockDocuments = (data: { dto: LockUnlockDocumentsInfoRequestDto }): Promise<LockUnlockResponseDto> => {
    if (servicesCtx?.documentsService && data.dto) {
      return servicesCtx.documentsService?.unlockDocuments(data.dto);
    }
    throw new Error('Services context not initialized');
  };

  return useMutation({
    mutationKey: mutateDocumentsKeys.unlockDocuments,
    mutationFn: (data: { dto: LockUnlockDocumentsInfoRequestDto }) => unlockDocuments(data),
    onSuccess: async () => {
      if (queryRequest) {
        await queryClient.invalidateQueries(searchKeys.filteredSearch(queryRequest));
      }
    }
  });
}
