import * as React from 'react';
import ServicesContext, { IServicesContext } from './ServicesContext';
import { ViewsService } from '../../context/view/ViewService';
import { SearchService } from '../../context/search/SearchService';
import { DocumentsService } from '../../context/documents/DocumentsService';
import { FieldsService } from '../../context/fields/FieldsService';
import { LocalesService } from '../../context/globalization/LocalesService';
import { CurrentUserService } from '../../context/currentUser/CurrentUserService';
import { UiAdminConfigsService } from '../../context/uiAdminConfigs/UiAdminConfigsService';
import { MsGraphService } from '../../context/msGraph/MsGraphService';

interface IServicesProviderProps {
  children: React.ReactNode;
}

const currentUserService = new CurrentUserService();
const viewsService = new ViewsService();
const fieldsService = new FieldsService();
const searchService = new SearchService();
const documentsService = new DocumentsService();
const localesService = new LocalesService();
const uiAdminConfigsService = new UiAdminConfigsService();
const msGraphService = new MsGraphService();

const servicesContext: IServicesContext = {
  currentUserService,
  viewsService,
  fieldsService,
  searchService,
  documentsService,
  localesService,
  uiAdminConfigsService,
  msGraphService
};

const ServicesProvider: React.FC<IServicesProviderProps> = props => {
  return <ServicesContext.Provider value={servicesContext}>{props.children}</ServicesContext.Provider>;
};

export default ServicesProvider;
