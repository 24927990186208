import * as React from 'react';
import { Typography } from '@progress/kendo-react-common';
import GearPage from './GearPage';
import { useLocalization } from '@progress/kendo-react-intl';
import { IntlHelper } from '../helpers/IntlHelper';
import { useUiAdminConfigsContext } from '../context/uiAdminConfigs/UiAdminConfigsContext';
import { GearHelpCenterState } from '../Model/GearHelpCenterState';

const NotExistingPage: React.FC = () => {
  const localization = useLocalization();
  const { uiAdminConfigState } = useUiAdminConfigsContext();
  const helpCenterAccessDeniedItem = (uiAdminConfigState.helpCenter as GearHelpCenterState)?.items?.find(
    item => item.isAccessDenied
  );
  return (
    <GearPage className="gearPage--accessDenied">
      <Typography.h2>{IntlHelper.toLangStr(localization, 'custom.accessDeniedPage.title')}</Typography.h2>
      <Typography.p>{IntlHelper.toLangStr(localization, 'custom.accessDeniedPage.description')}</Typography.p>
      {helpCenterAccessDeniedItem && (
        <a href={helpCenterAccessDeniedItem.url} target="_blank" rel="noreferrer">
          {helpCenterAccessDeniedItem.name}
        </a>
      )}
    </GearPage>
  );
};

export default NotExistingPage;
