import ky from 'ky';
import { apiRequest, graphRequest } from '../../config/authConfig';
import { InteractionRequiredAuthError } from '@azure/msal-common';
import { API_URL } from '../../config/consts';
import { msalInstance } from '../..';
import { SilentRequest } from '@azure/msal-browser';

const getTokenResponse = async (isMsGraphEndpoint: boolean) => {

    const activeAccount = msalInstance.getActiveAccount();
    const accounts = msalInstance.getAllAccounts();
    if (!activeAccount && accounts.length === 0) {
      throw new Error('User is not signed in.');
    }
    const silentRequest: SilentRequest = {
      account: activeAccount || accounts[0],
      scopes: []
    };

    if (isMsGraphEndpoint) {
      silentRequest.scopes = graphRequest.scopes;
    } else {
      silentRequest.scopes = apiRequest.scopes;
    }

    return msalInstance.acquireTokenSilent(silentRequest).catch(async error => {
      if (error instanceof InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        return msalInstance.acquireTokenRedirect(silentRequest);
      }
    });
};

const useKyClient = (isMsGraphEndpoint: boolean) => {
  const client = ky.extend({
    timeout: 100000,
    retry: 3,
    hooks: {
      beforeRequest: [
        options => {
          if (options.method !== 'GET') {
            const headersKeys = Array.from(options.headers.keys()).map(key => key.toLowerCase());
            if (!headersKeys.includes('content-type')) {
              options.headers.set('content-type', 'application/json');
            }
            if (!headersKeys.includes('accept')) {
              options.headers.set('accept', 'application/json');
            }
          }
        },
        async request => {
          const tokenResponse = await getTokenResponse(isMsGraphEndpoint);
          if (tokenResponse) {
            request.headers.set('Authorization', `Bearer ${tokenResponse.accessToken}`);
          }
        }
      ],
      afterResponse: [
        async (request, options, response) => {
          if (response.status === 401) {
            const tokenResponse = await getTokenResponse(isMsGraphEndpoint);
            if (tokenResponse) {
              request.headers.set(
                'Authorization',
                `Bearer ${isMsGraphEndpoint ? tokenResponse.accessToken : tokenResponse.idToken}`
              );
              return ky(request);
            }
          }
        }
      ]
    },
    prefixUrl: isMsGraphEndpoint ? undefined : API_URL
  });

  return client;
};

export default useKyClient;
