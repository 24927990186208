import { Icon, MessageBar, MessageBarButton, MessageBarType } from '@fluentui/react';
import * as React from 'react';
import { useUiAdminConfigsContext } from '../../context/uiAdminConfigs/UiAdminConfigsContext';
import { GearMessageBarState } from '../../Model/GearMessageBarState';
import { RoutePath } from '../../global/enums/RoutePath';
import { GearMaintenancePageState } from '../../Model/GearMaintenancePageState';

interface GearMessageBarProps {}

export const GearMessageBar: React.FC<GearMessageBarProps> = () => {
  const { uiAdminConfigState, uiAdminConfigPreviewState } = useUiAdminConfigsContext();
  const [userMessageBarState, setUserMessageBarState] = React.useState<GearMessageBarState>();

  React.useEffect(() => {
    if ((uiAdminConfigState.maintenancePage as GearMaintenancePageState)?.enabled) {
      setUserMessageBarState({
        enabled: true,
        messageContent:
          'ADMIN ONLY: Maintenance page is enabled for all users. To disable it go to Settings -> Admin Center -> Maintenance Page',
        messageBarType: MessageBarType.warning,
        truncated: false,
        dismissable: false,
        hyperlinkText: 'PREVIEW',
        hyperlinkAddress: `${location.origin}/${RoutePath.MAINTENANCE}`
      });
    } else if (uiAdminConfigPreviewState.messageBar) {
      setUserMessageBarState(uiAdminConfigPreviewState.messageBar as GearMessageBarState);
    } else if (uiAdminConfigState.messageBar) {
      setUserMessageBarState(uiAdminConfigState.messageBar as GearMessageBarState);
    } else {
      setUserMessageBarState(undefined);
    }
  }, [uiAdminConfigState, uiAdminConfigPreviewState]);
  return (
    <>
      {userMessageBarState?.enabled && (
        <MessageBar
          className="gearMessageBar"
          messageBarType={userMessageBarState.messageBarType}
          isMultiline={userMessageBarState.isMultiline || false}
          dismissButtonAriaLabel="Close"
          truncated={userMessageBarState.truncated}
          {...(userMessageBarState.dismissable && {
            onDismiss: () => setUserMessageBarState({ ...userMessageBarState, enabled: false })
          })}
          actions={
            userMessageBarState.hyperlinkAddress ? (
              <div>
                <MessageBarButton onClick={() => window.open(userMessageBarState.hyperlinkAddress, '_blank')}>
                  {userMessageBarState.hyperlinkText || ''}
                </MessageBarButton>
                <Icon
                  className="collapseIcon"
                  iconName={userMessageBarState.isMultiline ? 'DoubleChevronUp' : 'DoubleChevronDown'}
                  onClick={() =>
                    setUserMessageBarState({ ...userMessageBarState, isMultiline: !!!userMessageBarState.isMultiline })
                  }
                />
              </div>
            ) : undefined
          }
        >
          {userMessageBarState.messageContent}
        </MessageBar>
      )}
    </>
  );
};
