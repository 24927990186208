import { BrowserCacheLocation, Configuration, PopupRequest } from '@azure/msal-browser';
import { MS_GRAPH_V1_URI } from '../Model/CommonProps';

export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_REGISTRATION_CLIENT_ID || '',
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_TENANT_ID}`,
    redirectUri: '/',
    postLogoutRedirectUri: '/'
  },
  cache: {
    cacheLocation: BrowserCacheLocation.SessionStorage
  }
};

// Files.Read.All scope consent only for QA and PROD
export const apiRequest: PopupRequest = {
  scopes: [process.env.REACT_APP_REGISTRATION_CLIENT_ID + '/.default']
};

export const graphRequest: PopupRequest = {
  scopes: [
    'User.Read',
    ...(process.env.REACT_APP_ENV === 'QA' || process.env.REACT_APP_ENV === 'PROD' ? ['Files.Read.All'] : [])
  ]
};

export const graphConfig = {
  graphMeEndpoint: `${MS_GRAPH_V1_URI}/me`,
  graphSharesEndpoint: `${MS_GRAPH_V1_URI}/shares`,
  graphGetDriveItem: (shareIdOrEncodedSharingUrl: string) =>
    `${graphConfig.graphSharesEndpoint}/${shareIdOrEncodedSharingUrl}/driveItem?select=id,@microsoft.graph.downloadUrl,name`
};
