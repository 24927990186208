import { TileLayout, TileLayoutItem, TilePosition } from '@progress/kendo-react-layout';
import * as React from 'react';
import { HelpCenterMenu } from './HelpCenterMenu';
import { HelpCenterDocument } from './HelpCenterDocument';
import { IntlHelper } from '../../../helpers/IntlHelper';
import { useLocalization } from '@progress/kendo-react-intl';
import { useState } from 'react';
import { useUiAdminConfigsContext } from '../../../context/uiAdminConfigs/UiAdminConfigsContext';
import { GearHelpCenterItemState, GearHelpCenterState } from '../../../Model/GearHelpCenterState';
import { GEAR_HELP_CENTER_MENU_ITEM_TYPE } from '../../../global/enums/GearHelpCenterMenuItemType';

const postitions: TilePosition[] = [
  {
    col: 1,
    colSpan: 1
  },
  {
    col: 2,
    colSpan: 50
  }
];

export const HelpCenterManagement: React.FC = () => {
  const localization = useLocalization();
  const { uiAdminConfigState } = useUiAdminConfigsContext();
  const [selectedMenuItem, setSelectedMenuItem] = useState<GearHelpCenterItemState>();
  const helpCenterItems = (uiAdminConfigState.helpCenter as GearHelpCenterState)?.items?.filter(
    item => !item.isAccessDenied
  );

  const menu: GearHelpCenterItemState[] = React.useMemo(() => {
    const reportIssueItem: GearHelpCenterItemState = {
      id: GEAR_HELP_CENTER_MENU_ITEM_TYPE.RAPORT_ISSUE,
      name: IntlHelper.toLangStr(localization, 'custom.settings.helpCenter.reportIssue.title')
    };
    if (!helpCenterItems) {
      return [reportIssueItem];
    }
    return helpCenterItems.concat(reportIssueItem);
  }, [helpCenterItems]);

  React.useEffect(() => {
    if (!selectedMenuItem) {
      setSelectedMenuItem(menu[0]);
    }
  }, [menu]);

  const tiles: TileLayoutItem[] = [
    {
      body: (
        <HelpCenterMenu
          menu={menu}
          value={selectedMenuItem}
          onSelectedMenuItemChanged={(value?: GearHelpCenterItemState) => setSelectedMenuItem(value)}
        />
      ),
      className: 'helpCenterTileLayoutItem',
      reorderable: false,
      resizable: false
    },
    {
      body: <HelpCenterDocument selectedMenuItem={selectedMenuItem} />,
      className: 'helpCenterTileLayoutItem',
      reorderable: false,
      resizable: false
    }
  ];
  return (
    <div className="helpCenter">
      <TileLayout columns={2} positions={postitions} items={tiles} />
    </div>
  );
};
