import * as React from 'react';
import { UiAdminConfigsContext } from './UiAdminConfigsContext';
import { useGetUiAdminConfigEntry } from './useGetUiAdminConfigEntry';
import { useNotificationContext } from '../../global/hooks/useNotificationContext';
import { useUpsertUiAdminConfigEntry } from './useUpsertUiAdminConfigEntry';
import { IntlHelper } from '../../helpers/IntlHelper';
import { useLocalization } from '@progress/kendo-react-intl';
import { useGetUiAdminConfigWritePermissions } from './useGetUiAdminConfigWritePermissions';
import { GEAR_UI_ADMIN_CONFIG_TYPE, GearUiAdminConfigType } from '../../Model/uiAdminConfig/GearUiAdminConfigType';
import { GearUiAdminConfigState } from '../../Model/uiAdminConfig/GearUiAdminConfigState';
import { useLocation } from 'react-router-dom';
import { GearHelpCenterItemState, GearHelpCenterState } from '../../Model/GearHelpCenterState';

interface UiAdminConfigsProviderProps {
  children?: React.ReactNode;
}

export const UiAdminConfigsProvider: React.FC<UiAdminConfigsProviderProps> = ({ children }) => {
  const [configKey, setConfigKey] = React.useState<GearUiAdminConfigType>();
  const [configWritePermissionsKeys, setConfigWritePermissionsKeys] = React.useState<GearUiAdminConfigType[]>();
  const [uiAdminConfigState, setUiAdminConfigState] = React.useState<GearUiAdminConfigState>({});
  const {
    data: uiAdminConfigEntry,
    isLoading: isLoadingGetUiAdminConfigEntry,
    isFetched: isFetchedGetUiAdminConfigEntry
  } = useGetUiAdminConfigEntry(configKey);
  const { data: uiAdminConfigWritePermissions, isLoading: isLoadingUiAdminConfigWritePermissions } =
    useGetUiAdminConfigWritePermissions(configWritePermissionsKeys);
  const { mutate: mutateUpsertUiAdminConfig, isLoading: isLoadingMutateUpsertUiConfigEntry } =
    useUpsertUiAdminConfigEntry();
  const { showNotification } = useNotificationContext();
  const localization = useLocalization();
  const { pathname } = useLocation();

  const handleUpsertUiAdminConfigEntry = (key: GearUiAdminConfigType, body: string): void => {
    if (key !== configKey) {
      setConfigKey(key);
    }
    mutateUpsertUiAdminConfig(
      { key, body },
      {
        onSuccess: () => {
          let messageKey = '';
          switch (key) {
            case GEAR_UI_ADMIN_CONFIG_TYPE.MESSAGE_BAR:
              messageKey = 'custom.notifications.messageBarSaved';
              break;
            case GEAR_UI_ADMIN_CONFIG_TYPE.MAINTENANCE_PAGE:
              messageKey = 'custom.notifications.maintenancePageSaved';
              break;
            case GEAR_UI_ADMIN_CONFIG_TYPE.HELP_CENTER:
              messageKey = 'custom.notifications.helpCenterSaved';
              break;
            case GEAR_UI_ADMIN_CONFIG_TYPE.REPORT_ISSUE:
              messageKey = 'custom.notifications.reportIssueSaved';
              break;
            default:
              break;
          }
          showNotification({
            type: 'success',
            message: IntlHelper.toLangStr(localization, messageKey)
          });
        }
      }
    );
  };

  const [uiAdminConfigPreviewState, setUiAdminConfigPreviewState] = React.useState<GearUiAdminConfigState>({
    [GEAR_UI_ADMIN_CONFIG_TYPE.MESSAGE_BAR]: undefined,
    [GEAR_UI_ADMIN_CONFIG_TYPE.MAINTENANCE_PAGE]: undefined,
    [GEAR_UI_ADMIN_CONFIG_TYPE.HELP_CENTER]: undefined,
    [GEAR_UI_ADMIN_CONFIG_TYPE.REPORT_ISSUE]: undefined
  });

  React.useEffect(() => {
    // GET initial UiAdminConfigState
    if (!(GEAR_UI_ADMIN_CONFIG_TYPE.MAINTENANCE_PAGE in uiAdminConfigState)) {
      setConfigKey(GEAR_UI_ADMIN_CONFIG_TYPE.MAINTENANCE_PAGE);
    } else if (!(GEAR_UI_ADMIN_CONFIG_TYPE.MESSAGE_BAR in uiAdminConfigState)) {
      setConfigKey(GEAR_UI_ADMIN_CONFIG_TYPE.MESSAGE_BAR);
    } else if (!(GEAR_UI_ADMIN_CONFIG_TYPE.HELP_CENTER in uiAdminConfigState)) {
      setConfigKey(GEAR_UI_ADMIN_CONFIG_TYPE.HELP_CENTER);
    } else if (!(GEAR_UI_ADMIN_CONFIG_TYPE.REPORT_ISSUE in uiAdminConfigState)) {
      setConfigKey(GEAR_UI_ADMIN_CONFIG_TYPE.REPORT_ISSUE);
    }
  }, [uiAdminConfigState]);

  React.useEffect(() => {
    if (!configKey || !setUiAdminConfigState || !isFetchedGetUiAdminConfigEntry) {
      return;
    }
    setUiAdminConfigState({
      ...uiAdminConfigState,
      [configKey]: uiAdminConfigEntry ? JSON.parse(uiAdminConfigEntry) : {}
    });
  }, [uiAdminConfigEntry]);

  React.useEffect(() => {
    // reset preview State on route change
    setUiAdminConfigPreviewState({
      [GEAR_UI_ADMIN_CONFIG_TYPE.MESSAGE_BAR]: undefined,
      [GEAR_UI_ADMIN_CONFIG_TYPE.MAINTENANCE_PAGE]: undefined,
      [GEAR_UI_ADMIN_CONFIG_TYPE.HELP_CENTER]: undefined,
      [GEAR_UI_ADMIN_CONFIG_TYPE.REPORT_ISSUE]: undefined
    });
  }, [pathname]);

  const refreshUiAdminConfigState = (newConfigKey: GearUiAdminConfigType) => {
    setConfigKey(newConfigKey);
  };

  const [tempHelpCenterState, setTempHelpCenterState] = React.useState<Partial<GearHelpCenterState>>();

  React.useEffect(() => {
    if (
      isLoadingGetUiAdminConfigEntry ||
      isLoadingMutateUpsertUiConfigEntry ||
      isLoadingUiAdminConfigWritePermissions
    ) {
      return;
    }
    if (uiAdminConfigState.helpCenter) {
      const items: GearHelpCenterItemState[] = (uiAdminConfigState.helpCenter as GearHelpCenterState).items;
      setTempHelpCenterState({ items });
    } else {
      setTempHelpCenterState({});
    }
  }, [uiAdminConfigState.helpCenter]);

  const handleHelpCenterDragStart = (dataItem: GearHelpCenterItemState) => {
    setTempHelpCenterState({ ...tempHelpCenterState, activeItem: dataItem });
  };

  const handleReorderHelpCenter = (dataItem: GearHelpCenterItemState, direction: 'before' | 'after' | null) => {
    if (tempHelpCenterState?.activeItem?.id === dataItem.id || !tempHelpCenterState?.items) {
      return;
    }
    const reorderedData = tempHelpCenterState.items.slice();
    const prevIndex = reorderedData.findIndex(p => p.id === tempHelpCenterState.activeItem?.id);
    let nextIndex = reorderedData.findIndex(p => p.id === dataItem.id) + (direction === 'before' ? -1 : 0);

    if (prevIndex > nextIndex) {
      nextIndex++;
    }
    reorderedData.splice(prevIndex, 1);
    reorderedData.splice(nextIndex, 0, tempHelpCenterState.activeItem || reorderedData[0]);

    setTempHelpCenterState({ ...tempHelpCenterState, items: reorderedData });
  };

  return (
    <UiAdminConfigsContext.Provider
      value={{
        isLoading:
          isLoadingGetUiAdminConfigEntry ||
          isLoadingMutateUpsertUiConfigEntry ||
          isLoadingUiAdminConfigWritePermissions,
        upsertUiAdminConfigEntry: handleUpsertUiAdminConfigEntry,
        setConfigWritePermissionsKeys,
        uiAdminConfigWritePermissions,
        uiAdminConfigPreviewState,
        setUiAdminConfigPreviewState,
        uiAdminConfigState,
        refreshUiAdminConfigState,
        tempHelpCenterState,
        setTempHelpCenterState,
        onHelpCenterDragStart: handleHelpCenterDragStart,
        onHelpCenterReorder: handleReorderHelpCenter
      }}
    >
      {children}
    </UiAdminConfigsContext.Provider>
  );
};
