import { KyResponse, Options, ResponsePromise } from 'ky';
import { ApiResponse } from '../../Model/ApiResponse';
import useKyClient from '../../global/hooks/useKyClient';
import { MS_GRAPH_V1_URI } from '../../Model/CommonProps';

type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';

export const ExecuteEndpoint = async <TResult>(
  endpoint: string,
  httpMethod: HttpMethod,
  options?: Options
): Promise<TResult> => {
  const client = useKyClient(endpoint.includes(MS_GRAPH_V1_URI));
  let response: ResponsePromise;

  try {
    switch (httpMethod) {
      case 'GET':
        response = client.get(endpoint, options);
        break;
      case 'POST':
        response = client.post(endpoint, options);
        break;
      case 'PUT':
        response = client.put(endpoint, options);
        break;
      case 'DELETE':
        response = client.delete(endpoint, options);
        break;
      case 'PATCH':
        response = client.patch(endpoint, options);
        break;
      default:
        response = client.get(endpoint, options);
    }

    /* eslint-disable @typescript-eslint/no-explicit-any */
    const apiResponse: any = await response.json();

    switch (endpoint.includes(MS_GRAPH_V1_URI)) {
      case true:
        if (apiResponse.error) {
          throw new Error(apiResponse.error?.message);
        } else {
          return apiResponse;
        }
      case false:
        const gearApiResponse = apiResponse as ApiResponse;
        if (gearApiResponse.isValid) {
          return apiResponse.result;
        }
        if (gearApiResponse.errorMessage) {
          throw new Error(gearApiResponse.errorMessage);
        }
        break;
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
  throw new Error('Unspecified error.');
};

export const ExecuteGetFile = async (endpoint: string): Promise<KyResponse> => {
  const client = useKyClient(endpoint.includes(MS_GRAPH_V1_URI));
  try {
    return await client.get(endpoint);
  } catch (err) {
    console.log(err);
    throw err;
  }
};
