import * as React from 'react';
import { QueryClient, QueryClientProvider, QueryCache, MutationCache } from '@tanstack/react-query';
import { appInsights } from '../context/applicationInsights/ApplicationInsightsService';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { useNotificationContext } from '../global/hooks/useNotificationContext';
import { useMemo } from 'react';
import { HTTPError } from 'ky';
import { graphConfig } from '../config/authConfig';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

interface QueryProviderWrapperProps {
  children: React.ReactNode;
}

export const QueryProviderWrapper: React.FC<QueryProviderWrapperProps> = ({ children }) => {
  const { showNotification } = useNotificationContext();
  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: 2000
          }
        },
        queryCache: new QueryCache({
          onError: (error, query) => {
            if (query.state.error) {
              if (error instanceof Error) {
                const { response: errorResponse } = error as HTTPError;
                if (
                  errorResponse &&
                  errorResponse.status === 403 &&
                  errorResponse.url.includes(graphConfig.graphSharesEndpoint)
                ) {
                  // Silently ignore 403 errors for MS Graph shares endpoint
                  return;
                }
                const errorMessage = `Something went wrong: ${error.message}`;
                showNotification({ type: 'error', message: errorMessage });
                appInsights.trackException({
                  error: new Error(`FRONTEND: ${errorMessage}`),
                  severityLevel: SeverityLevel.Error
                });
              } else {
                const errorMessage = `Something went wrong for query: ${query.queryKey}`;
                showNotification({ type: 'error', message: errorMessage });
                appInsights.trackException({
                  error: new Error(`FRONTEND: ${errorMessage}`),
                  severityLevel: SeverityLevel.Error
                });
              }
            }
          }
        }),
        mutationCache: new MutationCache({
          onError: (error, _variables, _context, mutation) => {
            if (mutation.options.onError) {
              return;
            }
            if (error instanceof Error) {
              const errorMessage = `Something went wrong: ${error.message}`;
              showNotification({ type: 'error', message: errorMessage });
              appInsights.trackException({
                error: new Error(`FRONTEND: ${errorMessage}`),
                severityLevel: SeverityLevel.Error
              });
            } else {
              const errorMessage = `Something went wrong: ${error}`;
              appInsights.trackException({
                error: new Error(`FRONTEND: ${errorMessage}`),
                severityLevel: SeverityLevel.Error
              });
            }
          }
        })
      }),
    []
  );
  return (
    <QueryClientProvider client={queryClient}>
      {children}
      {/* By default, React Query Devtools are only included in bundles when process.env.NODE_ENV === 'development' */}
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};
