export const HELP_CENTER_VIEW_FIELD = {
  NAME: 'name',
  URL: 'url',
  REMOVE: 'remove',
  IS_NEW: 'isNew',
  IS_ACCESS_DENIED: 'isAccessDenied'
} as const;

export interface SettingsViewDataItem {
  [HELP_CENTER_VIEW_FIELD.NAME]: string;
  [HELP_CENTER_VIEW_FIELD.URL]: string;
  [HELP_CENTER_VIEW_FIELD.REMOVE]?: string;
  [HELP_CENTER_VIEW_FIELD.IS_NEW]?: boolean;
  [HELP_CENTER_VIEW_FIELD.IS_ACCESS_DENIED]?: boolean;
}
