import { GridCellProps } from '@progress/kendo-react-grid';
import * as React from 'react';
import { EMPTY_VALUE } from '../../../config/consts';
import { GearGridCell } from './GearGridCell';

export interface IBooleanCellProps extends GridCellProps {}

export const BooleanCell: React.FC<IBooleanCellProps> = props => {
  const field = props.field || EMPTY_VALUE;
  const value = props.dataItem[field];
  return (
    <GearGridCell {...props} title={value}>
      {typeof value === 'boolean' ? value.toString() : EMPTY_VALUE}
    </GearGridCell>
  );
};
