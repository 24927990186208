import * as React from 'react';
import GearPage from './GearPage';
import TopBar from '../components/topBar/TopBar';
import { SettingsNavigation } from '../components/settings/SettingsNavigation';
import { SettingsContent } from '../components/settings/SettingsContent';
import { useUiAdminConfigsContext } from '../context/uiAdminConfigs/UiAdminConfigsContext';
import GearLoader from '../components/common/GearLoader';
import { useLocation, useNavigate } from 'react-router-dom';
import { RoutePath } from '../global/enums/RoutePath';
import { GEAR_UI_ADMIN_CONFIG_TYPE } from '../Model/uiAdminConfig/GearUiAdminConfigType';

const SettingsPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setConfigWritePermissionsKeys, uiAdminConfigWritePermissions, isLoading } = useUiAdminConfigsContext();
  React.useEffect(() => {
    if (setConfigWritePermissionsKeys) {
      setConfigWritePermissionsKeys([
        GEAR_UI_ADMIN_CONFIG_TYPE.MESSAGE_BAR,
        GEAR_UI_ADMIN_CONFIG_TYPE.MAINTENANCE_PAGE,
        GEAR_UI_ADMIN_CONFIG_TYPE.HELP_CENTER,
        GEAR_UI_ADMIN_CONFIG_TYPE.REPORT_ISSUE
      ]);
    }
  }, [setConfigWritePermissionsKeys]);

  React.useEffect(() => {
    const isNoAccessToAdminCenter =
      !isLoading &&
      uiAdminConfigWritePermissions &&
      !uiAdminConfigWritePermissions.messageBar &&
      !uiAdminConfigWritePermissions.maintenancePage;
    if (isNoAccessToAdminCenter && location.pathname.includes(RoutePath.SETTINGS_ADMIN_CENTER)) {
      navigate(RoutePath.ACCESS_DENIED);
    }
  }, [uiAdminConfigWritePermissions, isLoading]);

  return (
    <GearPage className="settingsPage">
      <TopBar />
      {!uiAdminConfigWritePermissions ? (
        <GearLoader />
      ) : (
        <>
          <SettingsNavigation uiAdminConfigWritePermissions={uiAdminConfigWritePermissions} />
          <SettingsContent uiAdminConfigWritePermissions={uiAdminConfigWritePermissions} />
        </>
      )}
    </GearPage>
  );
};

export default SettingsPage;
