import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import ServicesContext from '../../global/services/ServicesContext';
import { DriveItem } from '../../Model/responseDtos/graph/DriveItem';
import { msGraphKeys } from './queryKeyFactory';

export function useGetDriveItemFromUrl(fileUrl?: string): UseQueryResult<DriveItem, unknown> {
  const servicesCtx = useContext(ServicesContext);

  const getDriveItem = () => {
    if (servicesCtx?.msGraphService && fileUrl) {
      return servicesCtx.msGraphService.getDriveItem(fileUrl);
    }
    throw new Error('Services context not initialized');
  };

  return useQuery({
    queryKey: msGraphKeys.getDriveItemFromUrl(fileUrl),
    queryFn: getDriveItem,
    retry: 0,
    staleTime: 180000,
    cacheTime: 180000,
    enabled: !!fileUrl && (process.env.REACT_APP_ENV === 'QA' || process.env.REACT_APP_ENV === 'PROD')
  });
}
