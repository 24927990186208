import * as React from 'react';
import { useSearchContext } from '../../../../context/search/SearchContext';
import { useViewsContext } from '../../../../context/view/ViewsContext';
import { GlobalFilterState } from './GlobalFilterState';
import { GlobalFilter } from './GlobalFilter';
import { GEAR_FIELD_TYPE } from '../../../../global/enums/GearFieldType';
import { GroupedDateGlobalFilter } from './GroupedDateGlobalFilter';
import { useGetAllFields } from '../../../../context/fields/useGetAllFields';
import { useGlobalizationContext } from '../../../../context/globalization/GlobalizationContext';

export const GlobalFilters: React.FC = () => {
  const {
    defaultUserView,
    globalFiltersState,
    setGlobalFiltersState,
    globalFilterDateFieldSelected,
    setGlobalFilterDateFieldSelected
  } = useViewsContext();
  const { data } = useSearchContext();
  const { selectedLocale } = useGlobalizationContext();
  const { data: fields } = useGetAllFields(selectedLocale);

  const getGlobalFieldDisplayName = React.useCallback(
    (name: string): string => {
      const foundField = fields?.find(field => field.name === name);
      if (!foundField) {
        return name;
      }
      return foundField.displayName || foundField.name;
    },
    [fields]
  );

  React.useEffect(() => {
    if (defaultUserView && data && setGlobalFiltersState) {
      if (defaultUserView.fields.some(field => field.facetable) && !data?.facets) {
        return;
      }
      const { globalFilters } = defaultUserView;
      const { facets } = data;

      const result: GlobalFilterState[] = globalFilters.map(filter => {
        const foundFacetName = Object.keys(facets).find(facet => facet === filter.name);
        return {
          ...filter,
          facetValues: foundFacetName ? facets[foundFacetName] : undefined,
          displayName: getGlobalFieldDisplayName(filter.name)
        };
      });
      setGlobalFiltersState(result);
    }
  }, [data?.facets, defaultUserView?.globalFilters, selectedLocale]);

  return (
    <div className="globalFilter">
      {globalFiltersState
        ?.filter(filter => filter.type !== GEAR_FIELD_TYPE.DATE && filter.type !== GEAR_FIELD_TYPE.DATETIME)
        .map(filter => (
          <div key={filter.id} className={'filterItem'}>
            <GlobalFilter filterState={filter} />
          </div>
        ))}
      {globalFiltersState?.some(
        filter => filter.type === GEAR_FIELD_TYPE.DATE || filter.type === GEAR_FIELD_TYPE.DATETIME
      ) && (
        <div key={'groupedDateGlobalFilter'} className={'filterItem'}>
          <GroupedDateGlobalFilter
            dateFiltersState={globalFiltersState.filter(
              filter => filter.type === GEAR_FIELD_TYPE.DATE || filter.type === GEAR_FIELD_TYPE.DATETIME
            )}
            fieldSelected={globalFilterDateFieldSelected}
            setFieldSelected={newFieldSelected => {
              if (setGlobalFilterDateFieldSelected) {
                setGlobalFilterDateFieldSelected(newFieldSelected);
              }
            }}
          />
        </div>
      )}
    </div>
  );
};
