import { useContext } from 'react';
import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';
import { mutateViewsKeys } from './mutateKeyFactory';
import ServicesContext from '../../global/services/ServicesContext';
import { viewsKeys } from './queryKeyFactory';

export function useRemovePrivateView(locale?: string, indexId?: number): UseMutationResult<boolean, unknown> {
  const servicesCtx = useContext(ServicesContext);
  const queryClient = useQueryClient();

  const removePrivateView = (): Promise<boolean> => {
    if (servicesCtx?.viewsService) {
      return servicesCtx.viewsService.removePrivateView(indexId);
    }
    throw new Error('Services context not initialized');
  };

  return useMutation({
    mutationKey: mutateViewsKeys.removePrivateView,
    mutationFn: () => removePrivateView(),
    onSuccess: async data => {
      if (data) {
        await queryClient.invalidateQueries(viewsKeys.privateViewByLocale(locale, indexId));
        await queryClient.invalidateQueries(viewsKeys.defaultViewByLocale(true, locale, indexId));
      }
    }
  });
}
