import { Chip, ChipList, ChipListChangeEvent, ChipProps } from '@progress/kendo-react-buttons';
import * as React from 'react';
import { GearHelpCenterItemState } from '../../../Model/GearHelpCenterState';

interface HelpCenterMenuProps {
  menu: GearHelpCenterItemState[];
  value?: GearHelpCenterItemState;
  onSelectedMenuItemChanged: (item?: GearHelpCenterItemState) => void;
}

export const HelpCenterMenu: React.FC<HelpCenterMenuProps> = props => {
  const handleChange = (event: ChipListChangeEvent): void => {
    const selectedItem = props.menu.find(item => item.id === event.value);
    props.onSelectedMenuItemChanged(selectedItem);
  };

  return (
    <ChipList
      className="helpCenterMenu"
      data={props.menu}
      selection="single"
      value={props.value}
      valueField="id"
      textField="name"
      onChange={handleChange}
      chip={(chipProps: ChipProps) => {
        return (
          <Chip
            {...chipProps}
            className={chipProps.value === props.value?.id ? 'helpCenterMenuItemSelected' : 'helpCenterMenuItem'}
          />
        );
      }}
    />
  );
};
