import * as React from 'react';
import { useViewsContext } from '../../../../context/view/ViewsContext';
import { GridCellProps, GridItemChangeEvent } from '@progress/kendo-react-grid';
import { useEffect, useMemo } from 'react';
import { RadioButtonCell } from '../../../grid/customCells/RadioButtonCell';
import { SETTINGS_VIEW_FIELD, SettingsViewDataItem } from '../SettingsViewDataItem';
import { RemoveCell } from '../../../grid/customCells/RemoveCell';
import { EditableWidthCell } from './EditableWidthCell';
import { EditableDropdownCell } from '../../../grid/customCells/EditableDropdownCell';
import { useFieldsContext } from '../../../../context/fields/FieldsContext';
import { NoPrivateViewContent } from './NoPrivateViewContent';
import { ViewGrid } from '../ViewGrid';
import { DragAndDrop } from '@progress/kendo-react-common';
import { DraggableRow } from './DraggableRow';
import { EXTENSION_FIELD } from '../../../../config/consts';
import { GearGridCell } from '../../../grid/customCells/GearGridCell';

export const PrivateView: React.FC = () => {
  const {
    privateView,
    privateViewLoading,
    privateViewState,
    setPrivateViewState,
    mutatePrivateViewLoading,
    onPrivateViewDragStart,
    onPrivateViewReorder
  } = useViewsContext();
  const { data: allFields } = useFieldsContext();
  useEffect(() => {
    if (privateView && setPrivateViewState) {
      const data = privateView.fields.map(
        field =>
          ({
            [SETTINGS_VIEW_FIELD.ID]: field.id,
            [SETTINGS_VIEW_FIELD.NAME]: field.displayName || field.name,
            [SETTINGS_VIEW_FIELD.WIDTH]: field.width,
            [SETTINGS_VIEW_FIELD.LINK]: field.isDocumentLink
          }) as SettingsViewDataItem
      );
      setPrivateViewState({ ...privateViewState, data });
    }
  }, [privateView, setPrivateViewState]);

  const availableFields: { [SETTINGS_VIEW_FIELD.ID]: number; [SETTINGS_VIEW_FIELD.NAME]: string }[] = useMemo(() => {
    if (!allFields) {
      return [];
    }
    return allFields
      .filter(field => !privateViewState.data.map(item => item[SETTINGS_VIEW_FIELD.ID]).includes(field.id))
      .map(field => ({
        [SETTINGS_VIEW_FIELD.ID]: field.id,
        [SETTINGS_VIEW_FIELD.NAME]: field.displayName || field.name
      }));
  }, [allFields, privateViewState.data]);

  const extensionFieldDisplayName = allFields?.find(field => field.name === EXTENSION_FIELD)?.displayName;

  const handleCellRender = React.useCallback(
    (tdElement: React.ReactElement<HTMLTableCellElement> | null, cellProps: GridCellProps) => {
      if (!cellProps.field) {
        return <td />;
      }
      if (cellProps.field === SETTINGS_VIEW_FIELD.LINK) {
        return (
          <RadioButtonCell
            {...cellProps}
            disabled={cellProps.dataItem[SETTINGS_VIEW_FIELD.NAME] === extensionFieldDisplayName}
            onRadioButtonChange={() => {
              if (!setPrivateViewState) {
                return;
              }
              const newPrivateViewData = privateViewState.data.map(
                dataItem =>
                  ({
                    ...dataItem,
                    [SETTINGS_VIEW_FIELD.LINK]: false,
                    ...(!dataItem[SETTINGS_VIEW_FIELD.LINK] &&
                      dataItem[SETTINGS_VIEW_FIELD.ID] === cellProps.dataItem?.id && {
                        [SETTINGS_VIEW_FIELD.LINK]: !dataItem[SETTINGS_VIEW_FIELD.LINK]
                      })
                  }) as SettingsViewDataItem
              );
              setPrivateViewState({
                ...privateViewState,
                data: newPrivateViewData
              });
            }}
          />
        );
      } else if (cellProps.field === SETTINGS_VIEW_FIELD.REMOVE) {
        return (
          <RemoveCell
            {...cellProps}
            onClick={() => {
              if (!setPrivateViewState) {
                return;
              }
              const newPrivateViewData = privateViewState.data.filter(
                dataItem => dataItem[SETTINGS_VIEW_FIELD.ID] !== cellProps.dataItem?.id
              );
              setPrivateViewState({
                ...privateViewState,
                data: newPrivateViewData
              });
            }}
          />
        );
      } else if (cellProps.field === SETTINGS_VIEW_FIELD.WIDTH) {
        return (
          <EditableWidthCell
            {...cellProps}
            onItemChange={(event: GridItemChangeEvent) => {
              if (setPrivateViewState) {
                setPrivateViewState({
                  ...privateViewState,
                  data: privateViewState.data.map(item =>
                    item[SETTINGS_VIEW_FIELD.ID] === event.dataItem[SETTINGS_VIEW_FIELD.ID]
                      ? { ...item, [SETTINGS_VIEW_FIELD.WIDTH]: event.value }
                      : item
                  )
                });
              }
            }}
          />
        );
      } else if (cellProps.field === SETTINGS_VIEW_FIELD.NAME && !cellProps.dataItem[SETTINGS_VIEW_FIELD.ID]) {
        return (
          <EditableDropdownCell
            {...cellProps}
            onItemChange={(event: GridItemChangeEvent) => {
              if (!setPrivateViewState) {
                return;
              }
              const newPrivateViewData = privateViewState.data.map(dataItem =>
                !dataItem[SETTINGS_VIEW_FIELD.ID]
                  ? ({
                      ...dataItem,
                      [SETTINGS_VIEW_FIELD.ID]: event.value.id,
                      [SETTINGS_VIEW_FIELD.NAME]: event.value.name,
                      [SETTINGS_VIEW_FIELD.IS_NEW]: undefined
                    } as SettingsViewDataItem)
                  : dataItem
              );
              setPrivateViewState({
                ...privateViewState,
                data: newPrivateViewData
              });
            }}
            options={availableFields}
            dataItemKey={SETTINGS_VIEW_FIELD.ID}
            textField={SETTINGS_VIEW_FIELD.NAME}
          />
        );
      }
      return (
        <GearGridCell {...cellProps} title={cellProps.dataItem[cellProps.field] || ''}>
          {cellProps.dataItem[cellProps.field] || ''}
        </GearGridCell>
      );
    },
    [privateViewState, availableFields]
  );

  return (
    <>
      {!privateViewLoading && !privateView && privateViewState?.data.length === 0 ? (
        <NoPrivateViewContent />
      ) : (
        <DragAndDrop>
          <ViewGrid
            type="PRIVATE_VIEW"
            data={privateViewState.data}
            cellRender={handleCellRender}
            rowRender={(row, rowProps) => (
              <DraggableRow
                elementProps={row.props}
                onDragStart={onPrivateViewDragStart}
                onReorder={onPrivateViewReorder}
                {...rowProps}
              />
            )}
            isLoading={privateViewLoading || mutatePrivateViewLoading}
          />
        </DragAndDrop>
      )}
    </>
  );
};
