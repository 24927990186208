import { createContext, useContext } from 'react';
import { DriveItem } from '../../Model/responseDtos/graph/DriveItem';

export interface MsGraphProps {
  fileUrl: string | undefined;
}

interface IMsGraphContext {
  msGraphProps: Partial<MsGraphProps>;
  setMsGraphProps: (msGraphProps: Partial<MsGraphProps>) => void;
  driveItem?: DriveItem;
  isDriveItemLoading: boolean;
}

export const MsGraphContext = createContext<IMsGraphContext>({
  msGraphProps: {},
  setMsGraphProps: () => {},
  isDriveItemLoading: false
});

export const useMsGraphContext = () => useContext(MsGraphContext);
