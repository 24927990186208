import * as React from 'react';
import { IntlHelper } from '../../../helpers/IntlHelper';
import { useLocalization } from '@progress/kendo-react-intl';
import { Button } from '@progress/kendo-react-buttons';
import { useUiAdminConfigsContext } from '../../../context/uiAdminConfigs/UiAdminConfigsContext';
import GearLoader from '../../common/GearLoader';
import { LoadingGridPanel } from '../../grid/LoadingGridPanel';
import { GEAR_UI_ADMIN_CONFIG_TYPE } from '../../../Model/uiAdminConfig/GearUiAdminConfigType';
import { ConfirmDialog } from '../../common/ConfirmDialog';
import { GEAR_CONFIRM_DIALOG_STATE, GearConfirmDialogState } from '../../../Model/GearConfirmDialogState';
import { GearHelpCenterItemState, GearHelpCenterState } from '../../../Model/GearHelpCenterState';
import { Tooltip } from '@progress/kendo-react-tooltip';
import {
  Grid,
  GridCellProps,
  GridColumn,
  GridItemChangeEvent,
  GridRowClickEvent,
  GridToolbar
} from '@progress/kendo-react-grid';
import HiddenHeader from '../../grid/HiddenHeader';
import { HELP_CENTER_VIEW_FIELD } from '../helpCenterManagement/HelpCenterViewDataItem';
import { DraggableRow } from '../viewsManagement/privateView/DraggableRow';
import { DragAndDrop } from '@progress/kendo-react-common';
import { DragHandleCell } from '../viewsManagement/privateView/DragHandleCell';
import * as _ from 'lodash';
import { RemoveCell } from '../../grid/customCells/RemoveCell';
import { plusIcon } from '@progress/kendo-svg-icons';
import { RadioButtonCell } from '../../grid/customCells/RadioButtonCell';
import LinkedCell from '../../grid/customCells/LinkedCell';

interface HelpCenterDialogState {
  visible: boolean;
  values?: GearHelpCenterItemState[];
}

export const HelpCenterManagement: React.FC = () => {
  const [dialogState, setDialogState] = React.useState<HelpCenterDialogState>({ visible: false });
  const [editID, setEditID] = React.useState<string>();
  const localization = useLocalization();

  const {
    isLoading,
    uiAdminConfigState,
    upsertUiAdminConfigEntry,
    tempHelpCenterState,
    setTempHelpCenterState,
    onHelpCenterDragStart,
    onHelpCenterReorder
  } = useUiAdminConfigsContext();

  React.useEffect(() => {
    if (isLoading) {
      return;
    }
    if (uiAdminConfigState.helpCenter && !tempHelpCenterState) {
      const items: GearHelpCenterItemState[] = (uiAdminConfigState.helpCenter as GearHelpCenterState).items;
      setTempHelpCenterState({ items });
    }
  }, [uiAdminConfigState]);

  const upsertHelpCenter = (values: GearHelpCenterItemState[]) => {
    const body = JSON.stringify({ items: values } as GearHelpCenterState);
    upsertUiAdminConfigEntry(GEAR_UI_ADMIN_CONFIG_TYPE.HELP_CENTER, body);
  };

  const handleSubmit = () => {
    setDialogState({ visible: true, values: tempHelpCenterState?.items });
  };

  const handleDialogConfirm = (confirmed: GearConfirmDialogState) => {
    if (confirmed === GEAR_CONFIRM_DIALOG_STATE.YES && dialogState.values) {
      upsertHelpCenter(dialogState.values);
    }
    setDialogState({ visible: false });
  };

  const addRecord = () => {
    const newItems = tempHelpCenterState?.items?.slice() || [];
    const newItemId = crypto.randomUUID();
    newItems.unshift({
      id: newItemId,
      name: '',
      url: ''
    });
    setTempHelpCenterState({ items: newItems });
    setEditID(newItemId);
  };

  const rowClick = (event: GridRowClickEvent) => {
    setEditID(event.dataItem.id);
  };

  const itemChange = (event: GridItemChangeEvent) => {
    const inEditID = event.dataItem.id;
    const field = event.field || '';
    if (tempHelpCenterState?.items) {
      const newItems = tempHelpCenterState.items?.map(item => {
        if (field === HELP_CENTER_VIEW_FIELD.IS_ACCESS_DENIED && event.value) {
          return { ...item, [field]: item.id === inEditID ? event.value : !event.value };
        } else if (item.id === inEditID) {
          return { ...item, [field]: event.value };
        } else {
          return item;
        }
      });
      setTempHelpCenterState({ items: newItems });
    }
  };

  const onItemRemove = (cellProps: GridCellProps) => {
    if (tempHelpCenterState?.items) {
      const removeItemIndex = tempHelpCenterState.items.findIndex(dataItem => dataItem.id === cellProps.dataItem?.id);
      if (removeItemIndex !== -1) {
        const newItems = [...tempHelpCenterState.items];
        newItems.splice(removeItemIndex, 1);
        setTempHelpCenterState({ items: newItems });
      }
    }
  };

  const handleCellRender = React.useCallback(
    (tdElement: React.ReactElement<HTMLTableCellElement> | null, cellProps: GridCellProps) => {
      if (!cellProps.field) {
        return <td />;
      }
      if (cellProps.field === HELP_CENTER_VIEW_FIELD.REMOVE) {
        return <RemoveCell {...cellProps} onClick={() => onItemRemove(cellProps)} />;
      } else if (cellProps.field === HELP_CENTER_VIEW_FIELD.IS_ACCESS_DENIED) {
        return (
          <RadioButtonCell
            {...cellProps}
            onRadioButtonChange={() => {
              const cellPropsChangeEvent = {
                dataItem: cellProps.dataItem,
                dataIndex: cellProps.dataIndex,
                field: cellProps.field,
                value: true
                /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
              } as any;
              if (cellProps.onChange) {
                cellProps.onChange(cellPropsChangeEvent);
              }
            }}
          />
        );
      } else if (
        cellProps.field === HELP_CENTER_VIEW_FIELD.URL &&
        !cellProps.dataItem.inEdit &&
        cellProps.dataItem.url
      ) {
        return (
          <LinkedCell
            {...cellProps}
            onLinkClicked={() => {
              window.open(cellProps.dataItem.url, '_blank')?.focus();
            }}
          />
        );
      }
      return tdElement;
    },
    [tempHelpCenterState]
  );

  if (!tempHelpCenterState) {
    return <GearLoader />;
  }

  return (
    <div className="helpCenterManagement">
      {isLoading && <LoadingGridPanel />}

      <DragAndDrop>
        <Tooltip openDelay={500} anchorElement="target" parentTitle={true} position="left">
          <Grid
            data={tempHelpCenterState.items?.map((item: GearHelpCenterItemState) => ({
              ...item,
              inEdit: item.id === editID
            }))}
            editField="inEdit"
            onRowClick={rowClick}
            onItemChange={itemChange}
            rowRender={(row, rowProps) => (
              <DraggableRow
                elementProps={row.props}
                {...rowProps}
                onDragStart={onHelpCenterDragStart}
                onReorder={onHelpCenterReorder}
              />
            )}
            dataItemKey="id"
            cellRender={handleCellRender}
          >
            <GridToolbar className="helpCenterManagementToolbar">
              <Button
                svgIcon={plusIcon}
                title={IntlHelper.toLangStr(localization, 'custom.settings.adminCenter.helpCenter.addItem')}
                className="k-toolbar-button"
                fillMode="flat"
                type="button"
                onClick={addRecord}
                disabled={tempHelpCenterState.items && tempHelpCenterState.items?.length >= 10}
              >
                {IntlHelper.toLangStr(localization, 'custom.settings.adminCenter.helpCenter.addItem')}
              </Button>
              <Button
                className="k-toolbar-button"
                themeColor="dark"
                title={IntlHelper.toLangStr(localization, 'custom.common.save')}
                disabled={_.isEqual(
                  tempHelpCenterState.items,
                  (uiAdminConfigState.helpCenter as GearHelpCenterState).items
                )}
                onClick={handleSubmit}
              >
                {IntlHelper.toLangStr(localization, 'custom.common.save')}
              </Button>
            </GridToolbar>
            <GridColumn
              title={IntlHelper.toLangStr(localization, 'custom.settings.views.gridHeader.order')}
              cell={DragHandleCell}
              width={100}
            />
            <GridColumn
              field={HELP_CENTER_VIEW_FIELD.NAME}
              title={IntlHelper.toLangStr(localization, 'custom.settings.views.gridHeader.name')}
              editor="text"
            />
            <GridColumn
              field={HELP_CENTER_VIEW_FIELD.URL}
              title={IntlHelper.toLangStr(localization, 'custom.settings.views.gridHeader.url')}
              editor="text"
            />
            <GridColumn
              field={HELP_CENTER_VIEW_FIELD.IS_ACCESS_DENIED}
              title={IntlHelper.toLangStr(localization, 'custom.settings.views.gridHeader.isAccessDenied')}
            />
            <GridColumn field={HELP_CENTER_VIEW_FIELD.REMOVE} width={80} headerCell={HiddenHeader} />
          </Grid>
        </Tooltip>
      </DragAndDrop>

      {dialogState.visible && (
        <ConfirmDialog
          message={IntlHelper.toLangStr(localization, 'custom.settings.adminCenter.helpCenter.confirmationMessage')}
          onConfirm={handleDialogConfirm}
        />
      )}
    </div>
  );
};
