import * as React from 'react';
import ServicesProvider from '../global/services/ServicesProvider';
import '../assets/scss/main.scss';
import Root from './Root';
import { ApplicationInsightsWrapper } from './applicationInsights/ApplicationInsightsWrapper';
import { QueryProviderWrapper } from './QueryProviderWrapper';
import { NotificationContextProvider } from '../context/notification/NotificationProvider';
import { initializeFileTypeIcons } from '@fluentui/react-file-type-icons';
import { dayjsSetup } from '../helpers/DayJsHelper';
import GlobalizationProvider from '../context/globalization/GlobalizationProvider';
import { globalizationSetup } from '../config/globalization/globalizationSetup';
import { SearchProvider } from '../context/search/SearchProvider';
import { ViewsProvider } from '../context/view/ViewsProvider';
import { DocumentsProvider } from '../context/documents/DocumentsProvider';
import { CurrentUserProvider } from '../context/currentUser/CurrentUserProvider';
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import { UiAdminConfigsProvider } from '../context/uiAdminConfigs/UiAdminConfigsProvider';
import { MsGraphProvider } from '../context/msGraph/MsGraphProvider';

const App: React.FC = () => {
  React.useEffect(() => {
    dayjsSetup();
    globalizationSetup();
    initializeFileTypeIcons();
    initializeIcons();
  }, []);
  return (
    <ApplicationInsightsWrapper>
      <NotificationContextProvider>
        <QueryProviderWrapper>
          <ServicesProvider>
            <GlobalizationProvider>
              <div className="app">
                <CurrentUserProvider>
                  <MsGraphProvider>
                    <SearchProvider>
                      <ViewsProvider>
                        <DocumentsProvider>
                          <UiAdminConfigsProvider>
                            <Root />
                          </UiAdminConfigsProvider>
                        </DocumentsProvider>
                      </ViewsProvider>
                    </SearchProvider>
                  </MsGraphProvider>
                </CurrentUserProvider>
              </div>
            </GlobalizationProvider>
          </ServicesProvider>
        </QueryProviderWrapper>
      </NotificationContextProvider>
    </ApplicationInsightsWrapper>
  );
};

export default App;
