import { GEAR_LOCALE } from '../Model/GearLocale';

export const API_URL =
  (window.location.origin.includes('azurefd.net') || window.location.origin.includes('volvo.com')
    ? window.location.origin
    : process.env.REACT_APP_API_URL) + '/api';

export const DEFAULT_ROWS_PER_PAGE = 100;
export const DEFAULT_COLUMN_WIDTH = 200;
export const MIN_COLUMN_WIDTH = 50;
export const MAX_COLUMN_WIDTH = 1000;
export const COLUMN_WIDTH_STEP = 50;
export const GRID_PAGE_SIZES = [100, 200, 300];

export const EMPTY_VALUE = '';

export const SELECTED_FIELD = 'selected';
export const NOTE_FIELD = 'note';
export const EXTENSION_FIELD = 'Cps_Extension';
export const DOC_ID_FIELD = 'Cps_DocId';
export const CONTENT_TYPE_FIELD = 'Cps_ContentType';
export const DOC_USER_ALLOWED_ACTIONS = 'userAllowedActions';

export const USER_LOCALE_SETTINGS_KEY = 'GearUserLocale';
export const USER_INDEX_SETTINGS_KEY = 'GearUserIndex';
export const DEFAULT_LOCALE = GEAR_LOCALE.enGB;
export const MAX_ITEMS_LIMIT_EXPORT_CSV = 100000;
export const TOO_MANY_REQUESTS_EXPORT_CSV = 30; // in seconds

export const RICH_TEXT_CHARACTER_LIMIT = 63999;
