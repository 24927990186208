import * as React from 'react';
import { IntlHelper } from '../../../helpers/IntlHelper';
import { useLocalization } from '@progress/kendo-react-intl';
import { Button } from '@progress/kendo-react-buttons';
import { useUiAdminConfigsContext } from '../../../context/uiAdminConfigs/UiAdminConfigsContext';
import GearLoader from '../../common/GearLoader';
import { LoadingGridPanel } from '../../grid/LoadingGridPanel';
import { GEAR_UI_ADMIN_CONFIG_TYPE } from '../../../Model/uiAdminConfig/GearUiAdminConfigType';
import { ConfirmDialog } from '../../common/ConfirmDialog';
import { GEAR_CONFIRM_DIALOG_STATE, GearConfirmDialogState } from '../../../Model/GearConfirmDialogState';
import { Field, Form, FormElement, FormRenderProps } from '@progress/kendo-react-form';
import { FormSwitcher } from '../../common/form/fields/FormSwitcher';
import { FormRichTextEditor } from '../../common/form/fields/FormRichTextEditor';
import { maxLengthRichTextEditor } from '../../common/form/validators/FormValidator';
import { GearReportIssueState } from '../../../Model/GearReportIssueState';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Editor, EditorUtils } from '@progress/kendo-react-editor';
import { FormTextBox } from '../../common/form/fields/FormTextBox';
import { FormTextArea } from '../../common/form/fields/FormMultilineText';
import ReportIssueBody from '../helpCenterManagement/ReportIssueBody';

interface ReportIssueDialogState {
  visible: boolean;
  values?: {
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    [name: string]: any;
  };
}

export const ReportIssueManagement: React.FC = () => {
  const [initialValues, setInitValues] = React.useState<Partial<GearReportIssueState>>();
  const [dialogState, setDialogState] = React.useState<ReportIssueDialogState>({ visible: false });
  const localization = useLocalization();

  const {
    isLoading,
    uiAdminConfigState,
    upsertUiAdminConfigEntry,
    uiAdminConfigPreviewState,
    setUiAdminConfigPreviewState,
    refreshUiAdminConfigState
  } = useUiAdminConfigsContext();

  const editor = React.createRef<Editor>();

  React.useEffect(() => {
    if (isLoading) {
      return;
    }
    if (uiAdminConfigState) {
      const values: GearReportIssueState = uiAdminConfigState.reportIssue as GearReportIssueState;
      if (editor.current) {
        const view = editor.current.view;
        if (view && values.keyUsersContactsContent) {
          EditorUtils.setHtml(view, values.keyUsersContactsContent);
        }
      }
      setInitValues(values);
    } else {
      setInitValues({});
    }
  }, [uiAdminConfigState]);

  const upsertReportIssueSection = (values: {
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    [name: string]: any;
  }) => {
    const body = JSON.stringify(values);
    upsertUiAdminConfigEntry(GEAR_UI_ADMIN_CONFIG_TYPE.REPORT_ISSUE, body);

    if (setUiAdminConfigPreviewState && uiAdminConfigPreviewState?.reportIssue) {
      setUiAdminConfigPreviewState({
        ...uiAdminConfigPreviewState,
        [GEAR_UI_ADMIN_CONFIG_TYPE.REPORT_ISSUE]: undefined
      });
    }

    refreshUiAdminConfigState(GEAR_UI_ADMIN_CONFIG_TYPE.REPORT_ISSUE);
  };

  const handleSubmit = (values: {
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    [name: string]: any;
  }) => {
    if (values.enabled) {
      setDialogState({ visible: true, values });
    } else {
      upsertReportIssueSection(values);
    }
  };

  const handlePreview = (formRenderProps: FormRenderProps) => {
    if (setUiAdminConfigPreviewState) {
      const reportIssuePreviewState: GearReportIssueState = {
        enabled: true,
        buttonLabel: formRenderProps.valueGetter('buttonLabel'),
        emailReceipient: formRenderProps.valueGetter('emailReceipient'),
        emailSubject: formRenderProps.valueGetter('emailSubject'),
        emailBody: formRenderProps.valueGetter('emailBody'),
        keyUsersContactsContent: formRenderProps.valueGetter('keyUsersContactsContent')
      };
      setUiAdminConfigPreviewState({
        ...uiAdminConfigPreviewState,
        [GEAR_UI_ADMIN_CONFIG_TYPE.REPORT_ISSUE]: reportIssuePreviewState
      });
    }
  };

  const discardFormChanges = () => {
    if (setUiAdminConfigPreviewState) {
      setUiAdminConfigPreviewState({
        ...uiAdminConfigPreviewState,
        [GEAR_UI_ADMIN_CONFIG_TYPE.REPORT_ISSUE]: undefined
      });
    }
  };

  const handleDialogConfirm = (confirmed: GearConfirmDialogState) => {
    if (confirmed === GEAR_CONFIRM_DIALOG_STATE.YES && dialogState.values) {
      upsertReportIssueSection(dialogState.values);
    }
    setDialogState({ visible: false });
  };

  if (!initialValues) {
    return <GearLoader />;
  }

  return (
    <div>
      {!initialValues && <LoadingGridPanel />}
      <div className="gearForm">
        <Form
          onSubmit={handleSubmit}
          initialValues={initialValues}
          render={(formRenderProps: FormRenderProps) => {
            return (
              <FormElement>
                <Field
                  name={'enabled'}
                  component={FormSwitcher}
                  label={IntlHelper.toLangStr(localization, 'custom.common.enable')}
                />
                <h3>
                  {IntlHelper.toLangStr(localization, 'custom.settings.adminCenter.reportIssue.reportIssueButton')}
                </h3>
                <Field
                  label={IntlHelper.toLangStr(localization, 'custom.settings.adminCenter.reportIssue.buttonLabel')}
                  name={'buttonLabel'}
                  component={FormTextBox}
                />
                <Field
                  label={IntlHelper.toLangStr(localization, 'custom.settings.adminCenter.reportIssue.emailReceipient')}
                  name={'emailReceipient'}
                  component={FormTextBox}
                />
                <Field
                  label={IntlHelper.toLangStr(localization, 'custom.settings.adminCenter.reportIssue.emailSubject')}
                  name={'emailSubject'}
                  component={FormTextBox}
                />
                <Field
                  label={IntlHelper.toLangStr(localization, 'custom.settings.adminCenter.reportIssue.emailBody')}
                  name={'emailBody'}
                  component={FormTextArea}
                />
                <h3>{IntlHelper.toLangStr(localization, 'custom.settings.adminCenter.reportIssue.keyUserContacts')}</h3>
                <Field
                  name={'keyUsersContactsContent'}
                  component={FormRichTextEditor}
                  validator={(value: string) => maxLengthRichTextEditor(value, localization)}
                />
                <div className={'gearFormButtons'}>
                  <Button
                    type={'button'}
                    themeColor={'secondary'}
                    fillMode="solid"
                    onClick={() => handlePreview(formRenderProps)}
                  >
                    {IntlHelper.toLangStr(localization, 'custom.common.preview')}
                  </Button>
                  <Button themeColor="dark" disabled={!formRenderProps.modified || !formRenderProps.valid}>
                    {IntlHelper.toLangStr(localization, 'custom.common.save')}
                  </Button>
                </div>
              </FormElement>
            );
          }}
        />
      </div>
      {(uiAdminConfigPreviewState.reportIssue as GearReportIssueState)?.enabled && (
        <Dialog
          onClose={discardFormChanges}
          title={
            IntlHelper.toLangStr(localization, 'custom.settings.adminCenter.reportIssue.title') +
            ' - ' +
            IntlHelper.toLangStr(localization, 'custom.common.preview')
          }
          closeIcon={true}
          width={'100%'}
          height={'100%'}
          contentStyle={{ padding: 0 }}
        >
          <ReportIssueBody
            className="previewRerportIssue"
            reportIssueState={uiAdminConfigPreviewState.reportIssue as GearReportIssueState}
          />
        </Dialog>
      )}
      {dialogState.visible && (
        <ConfirmDialog
          message={IntlHelper.toLangStr(localization, 'custom.settings.adminCenter.reportIssue.confirmationMessage')}
          onConfirm={handleDialogConfirm}
        />
      )}
    </div>
  );
};
