import { DropDownButton, DropDownButtonItemClickEvent } from '@progress/kendo-react-buttons';
import { useLocalization } from '@progress/kendo-react-intl';
import * as React from 'react';
import { IntlHelper } from '../../../helpers/IntlHelper';
import { useEffect, useMemo } from 'react';
import { useDocumentsContext } from '../../../context/documents/DocumentsContext';
import { useSearchContext } from '../../../context/search/SearchContext';
import { GearSearchExportRequest } from '../../../Model/GearSearchExportRequest';
import { useViewsContext } from '../../../context/view/ViewsContext';
import { SvgIcon } from '@progress/kendo-react-common';
import { SVGIcon, chevronDownIcon, downloadIcon, exportIcon, lockIcon, unlockIcon } from '@progress/kendo-svg-icons';
import { Label } from '@fluentui/react';
import { useGlobalizationContext } from '../../../context/globalization/GlobalizationContext';
import {
  CONTENT_TYPE_FIELD,
  DOC_ID_FIELD,
  MAX_ITEMS_LIMIT_EXPORT_CSV,
  TOO_MANY_REQUESTS_EXPORT_CSV
} from '../../../config/consts';
import { ConfirmDialog } from '../../common/ConfirmDialog';
import * as _ from 'lodash';
import { useLockDocuments } from '../../../context/documents/useLockDocuments';
import { useUnlockDocuments } from '../../../context/documents/useUnlockDocuments';
import { useCurrentUserContext } from '../../../context/currentUser/CurrentUserContext';
import { LockUnlockDocumentsInfoRequestDto } from '../../../Model/requests/LockUnlockDocumentsInfoRequestDto';
import { LockUnlockDocumentInfoDto } from '../../../Model/requests/LockUnlockDocumentInfoDto';
import { useNotificationContext } from '../../../global/hooks/useNotificationContext';
import { LockUnlockResponseDto } from '../../../Model/responseDtos/LockUnlockResponseDto';
import { GEAR_NOTIFICATION_TYPE, GearNotificationType } from '../../../Model/enums/GearNotificationType';
import { GEAR_USER_ALLOWED_ACTIONS } from '../../../Model/enums/GearUserAllowedActions';

enum ActionItemId {
  DOWNLOAD = 'download',
  EXPORT = 'export',
  LOCK = 'lock',
  UNLOCK = 'unlock'
}

interface DropDownButtonItem {
  id: ActionItemId;
  text: string;
  svgIcon: SVGIcon;
  isDisabled?: boolean;
}

const CONFIRM_DIALOG = {
  MAX_EXPORT_ITEMS: 'MAX_EXPORT_ITEMS',
  TOO_MANY_REQUESTS: 'TOO_MANY_REQUESTS'
};

export type ConfirmDialogType = (typeof CONFIRM_DIALOG)[keyof typeof CONFIRM_DIALOG];

interface TooManyRequestsTimer {
  querySearchExport: GearSearchExportRequest;
  timeLeft: number;
}

const FilterActions: React.FC = () => {
  const { data, queryRequest } = useSearchContext();
  const { defaultUserView } = useViewsContext();
  const localization = useLocalization();
  const { selectedLocale } = useGlobalizationContext();
  const { selectedDocuments, downloadFiles } = useDocumentsContext();
  const { handleTrigerExportToCsv } = useSearchContext();
  const [dialogVisible, setDialogVisible] = React.useState<ConfirmDialogType | undefined>(undefined);
  const [tooManyRequestsTimer, setTooManyRequestsTimer] = React.useState<TooManyRequestsTimer>();
  const { showNotification } = useNotificationContext();

  const { selectedIndex } = useCurrentUserContext();
  const { mutate: mutateLockDocuments } = useLockDocuments();
  const { mutate: mutateUnlockDocuments } = useUnlockDocuments();

  useEffect(() => {
    if (!tooManyRequestsTimer || !tooManyRequestsTimer?.timeLeft) {
      if (tooManyRequestsTimer && tooManyRequestsTimer.timeLeft === 0) {
        setTooManyRequestsTimer(undefined);
        if (dialogVisible === CONFIRM_DIALOG.TOO_MANY_REQUESTS) {
          setDialogVisible(undefined);
        }
      }
      return;
    }

    const intervalId = setInterval(() => {
      setTooManyRequestsTimer({
        ...tooManyRequestsTimer,
        timeLeft: tooManyRequestsTimer.timeLeft - 1
      });
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
  }, [tooManyRequestsTimer?.timeLeft]);

  const selectedDocArray = selectedDocuments || [];

  const items: DropDownButtonItem[] = useMemo(() => {
    const result = [
      {
        id: ActionItemId.EXPORT,
        text: IntlHelper.toLangStr(localization, 'custom.toolbar.actions.export.action'),
        svgIcon: exportIcon
      }
    ] as DropDownButtonItem[];
    if (selectedDocArray.length > 0) {
      result.push({
        id: ActionItemId.DOWNLOAD,
        text: IntlHelper.toLangStr(
          localization,
          selectedDocArray.length === 1
            ? 'custom.toolbar.actions.download.downloadFile'
            : 'custom.toolbar.actions.download.downloadZip'
        ),
        svgIcon: downloadIcon,
        isDisabled: !!selectedDocArray.find(
          selectedDoc => !selectedDoc.userAllowedActions.includes(GEAR_USER_ALLOWED_ACTIONS.DOWNLOAD)
        )
      });
      result.push({
        id: ActionItemId.LOCK,
        text: IntlHelper.toLangStr(localization, 'custom.toolbar.actions.lock.lockTitle'),
        svgIcon: lockIcon,
        isDisabled: !!selectedDocArray.find(
          selectedDoc => !selectedDoc.userAllowedActions.includes(GEAR_USER_ALLOWED_ACTIONS.LOCK)
        )
      });
      result.push({
        id: ActionItemId.UNLOCK,
        text: IntlHelper.toLangStr(localization, 'custom.toolbar.actions.lock.unlockTitle'),
        svgIcon: unlockIcon,
        isDisabled: !!selectedDocArray.find(
          selectedDoc => !selectedDoc.userAllowedActions.includes(GEAR_USER_ALLOWED_ACTIONS.LOCK)
        )
      });
    }
    return result;
  }, [selectedDocArray.length, localization.language]);

  const itemClicked = async (event: DropDownButtonItemClickEvent) => {
    if (event.item.isDisabled) {
      return;
    }
    switch (event.item.id) {
      case ActionItemId.DOWNLOAD:
        if (downloadFiles) {
          return downloadFiles();
        }
        return;
      case ActionItemId.LOCK:
        if (selectedDocuments && selectedIndex) {
          const documents: LockUnlockDocumentInfoDto[] = selectedDocuments.map(i => ({
            contentTypeName: i[CONTENT_TYPE_FIELD],
            documentId: i[DOC_ID_FIELD]
          }));
          const dto: LockUnlockDocumentsInfoRequestDto = { documents, indexId: selectedIndex.id };
          return mutateLockDocuments(
            { dto },
            {
              onSuccess: (response: LockUnlockResponseDto) => {
                const changedCount = response.changed.length;
                const skippedCount = response.skipped.length;
                let message = '';
                let type: GearNotificationType = GEAR_NOTIFICATION_TYPE.INFO;
                if (skippedCount > 0) {
                  type = GEAR_NOTIFICATION_TYPE.INFO;
                  message += `${changedCount} ${IntlHelper.toLangStr(localization, 'custom.toolbar.actions.lock.partialLock1')} ${skippedCount} ${IntlHelper.toLangStr(localization, 'custom.toolbar.actions.lock.partialLock2')}`;
                } else {
                  type = GEAR_NOTIFICATION_TYPE.SUCCESS;
                  message += IntlHelper.toLangStr(localization, 'custom.toolbar.actions.lock.successfullLock');
                }
                showNotification({
                  type,
                  message
                });
              }
            }
          );
        }
        return;
      case ActionItemId.UNLOCK:
        if (selectedDocuments && selectedIndex) {
          const documents: LockUnlockDocumentInfoDto[] = selectedDocuments.map(i => ({
            contentTypeName: i[CONTENT_TYPE_FIELD],
            documentId: i[DOC_ID_FIELD]
          }));
          const dto: LockUnlockDocumentsInfoRequestDto = { documents, indexId: selectedIndex.id };
          return mutateUnlockDocuments(
            { dto },
            {
              onSuccess: (response: LockUnlockResponseDto) => {
                const changedCount = response.changed.length;
                const skippedCount = response.skipped.length;
                let message = '';
                let type: GearNotificationType = GEAR_NOTIFICATION_TYPE.INFO;
                if (skippedCount > 0) {
                  type = GEAR_NOTIFICATION_TYPE.INFO;
                  message += `${changedCount} ${IntlHelper.toLangStr(localization, 'custom.toolbar.actions.lock.partialUnlock1')} ${skippedCount} ${IntlHelper.toLangStr(localization, 'custom.toolbar.actions.lock.partialUnlock2')}`;
                } else {
                  type = GEAR_NOTIFICATION_TYPE.SUCCESS;
                  message += IntlHelper.toLangStr(localization, 'custom.toolbar.actions.lock.successfullUnlock');
                }
                showNotification({
                  type,
                  message
                });
              }
            }
          );
        }
        return;
      case ActionItemId.EXPORT:
        if (handleTrigerExportToCsv) {
          if (data && data.totalCount > MAX_ITEMS_LIMIT_EXPORT_CSV) {
            setDialogVisible(CONFIRM_DIALOG.MAX_EXPORT_ITEMS);
            return;
          }
          const querySearchExport: GearSearchExportRequest = {
            filters: queryRequest?.filters,
            searchText: queryRequest?.searchText,
            searchFields: queryRequest?.searchFields,
            orderBy: queryRequest?.orderBy,
            orderByIsDescending: queryRequest?.orderByIsDescending,
            select: defaultUserView?.fields.map(column => column.name),
            locale: selectedLocale
          };
          if (tooManyRequestsTimer && _.isEqual(querySearchExport, tooManyRequestsTimer.querySearchExport)) {
            setDialogVisible(CONFIRM_DIALOG.TOO_MANY_REQUESTS);
            return;
          }
          setTooManyRequestsTimer({ querySearchExport, timeLeft: TOO_MANY_REQUESTS_EXPORT_CSV });
          return handleTrigerExportToCsv(querySearchExport);
        }
        return;
      default:
        return;
    }
  };

  const handleItemRender = (li: { item: DropDownButtonItem; itemIndex: number }) => {
    let tooltipMessage: string | undefined;
    switch (li.item.id) {
      case ActionItemId.EXPORT:
        break;
      case ActionItemId.DOWNLOAD:
        if (li.item.isDisabled) {
          tooltipMessage = IntlHelper.toLangStr(localization, 'custom.toolbar.actions.download.cannotDownload');
        }
        break;
      case ActionItemId.LOCK:
      case ActionItemId.UNLOCK:
        if (li.item.isDisabled) {
          tooltipMessage = IntlHelper.toLangStr(localization, 'custom.toolbar.actions.lock.cannotLock');
        }
        break;
      default:
        break;
    }
    return (
      <div
        className={`filterActionButtonOption ${li.item.isDisabled ? 'filterActionButtonOptionDisabled' : ''}`}
        title={tooltipMessage}
      >
        <SvgIcon icon={li.item.svgIcon} />
        <Label key={li.item.id}>{li.item.text}</Label>
      </div>
    );
  };

  return (
    <div>
      <DropDownButton
        disabled={data?.documentsWithPerms.length === 0}
        className="dropDownButtonIconRight"
        svgIcon={chevronDownIcon}
        text={IntlHelper.toLangStr(localization, 'custom.toolbar.actions.title')}
        items={items}
        itemRender={handleItemRender}
        themeColor={'base'}
        fillMode="outline"
        onItemClick={itemClicked}
      />
      {dialogVisible && (
        <ConfirmDialog
          className="filterActionsDialog"
          title={IntlHelper.toLangStr(
            localization,
            dialogVisible === CONFIRM_DIALOG.MAX_EXPORT_ITEMS
              ? 'custom.toolbar.actions.export.maxExportItems.dialogHeader'
              : 'custom.toolbar.actions.export.tooManyRequests.dialogHeader'
          )}
          message={
            IntlHelper.toLangStr(
              localization,
              dialogVisible === CONFIRM_DIALOG.MAX_EXPORT_ITEMS
                ? 'custom.toolbar.actions.export.maxExportItems.dialogBody'
                : 'custom.toolbar.actions.export.tooManyRequests.dialogBody'
            ) + `${tooManyRequestsTimer ? ` (${tooManyRequestsTimer?.timeLeft}s)` : ''}`
          }
          confirmButtonOnly={true}
          onConfirm={() => setDialogVisible(undefined)}
        />
      )}
    </div>
  );
};

export default FilterActions;
