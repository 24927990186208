import * as React from 'react';
import { PDFViewer } from '@progress/kendo-react-pdf-viewer';
import { useEffect, useRef, useState } from 'react';
import { ElementDimensions, useWindowDimensions } from '../../../global/hooks/useWindowDimensions';
import { GEAR_HELP_CENTER_MENU_ITEM_TYPE } from '../../../global/enums/GearHelpCenterMenuItemType';
import { GearHelpCenterItemState } from '../../../Model/GearHelpCenterState';
import { SampleDevTestFileBase64 } from './SampleDevTestFileBase64';
import { useMsGraphContext } from '../../../context/msGraph/MsGraphContext';
import ReportIssueBody from './ReportIssueBody';
import { CannotOpenFile } from './CannotOpenFile';
import { useUiAdminConfigsContext } from '../../../context/uiAdminConfigs/UiAdminConfigsContext';
import { GearReportIssueState } from '../../../Model/GearReportIssueState';

interface HelpCenterDocumentProps {
  selectedMenuItem?: GearHelpCenterItemState;
}

const defaultPage = 'Doc';
const embedPage = 'embed';
const powerPointExtensions = ['.pptx', '.pptm', '.ppt', '.pot', '.potm', '.ppsx', '.ppsm', '.pps'];

const powerPointView = 'interactivepreview';
const defaultView = 'embedview';

const extractContentBeforeLayouts = (url: string): string | null => {
  const regex = /^(.*)(?=\/_layouts\/15\/)/;
  const match = url.match(regex);
  return match ? match[1] : null;
};

const extractUniqueId = (url: string): string | null => {
  const regex = /[?&]UniqueId=([^&]+)/;
  const match = url.match(regex);
  return match ? decodeURIComponent(match[1]) : null;
};

export const HelpCenterDocument: React.FC<HelpCenterDocumentProps> = props => {
  const ref = useRef<HTMLDivElement>(null);
  const windowDimensions = useWindowDimensions();
  const [helpCenterDimensions, setHelpCenterDimensions] = useState<ElementDimensions>({ width: 0, height: 0 });
  useEffect(() => {
    if (ref && ref.current) {
      const paddingSize = 50;
      setHelpCenterDimensions({
        height: document.body.offsetHeight - ref.current.getBoundingClientRect().top - paddingSize,
        width: ref.current.getBoundingClientRect().width
      });
    }
  }, [windowDimensions]);

  const { uiAdminConfigState } = useUiAdminConfigsContext();
  const reportIssueState = uiAdminConfigState.reportIssue as GearReportIssueState;

  const { driveItem, msGraphProps, setMsGraphProps, isDriveItemLoading } = useMsGraphContext();

  useEffect(() => {
    setMsGraphProps({ ...msGraphProps, fileUrl: props.selectedMenuItem?.url });
  }, [props.selectedMenuItem]);

  const getSrcLink = React.useCallback(() => {
    if (!driveItem) {
      return;
    }
    const serverRelativeUrl = extractContentBeforeLayouts(driveItem['@microsoft.graph.downloadUrl']);
    const uniqueId = extractUniqueId(driveItem['@microsoft.graph.downloadUrl']);
    if (driveItem.name.endsWith('.pdf') || driveItem.name.endsWith('.txt')) {
      return `${serverRelativeUrl}/_layouts/15/${embedPage}.aspx?UniqueId=${uniqueId}`;
    }
    const currentAction = powerPointExtensions.some(el => driveItem.name.endsWith(el)) ? powerPointView : defaultView;
    return `${serverRelativeUrl}/_layouts/15/${defaultPage}.aspx?sourcedoc=${uniqueId}&action=${currentAction}`;
  }, [driveItem]);

  return (
    <div ref={ref} className="helpCeneterContent">
      {props.selectedMenuItem?.id === GEAR_HELP_CENTER_MENU_ITEM_TYPE.RAPORT_ISSUE &&
      reportIssueState &&
      reportIssueState.enabled ? (
        <ReportIssueBody reportIssueState={reportIssueState} />
      ) : !(process.env.REACT_APP_ENV === 'QA' || process.env.REACT_APP_ENV === 'PROD') ? (
        <PDFViewer data={SampleDevTestFileBase64} style={{ height: helpCenterDimensions.height }} />
      ) : driveItem ? (
        <iframe
          style={{ height: helpCenterDimensions.height, width: helpCenterDimensions.width }}
          src={getSrcLink()}
          id="documenttemplateframe"
        />
      ) : (
        !isDriveItemLoading && <CannotOpenFile />
      )}
    </div>
  );
};
