import { Button } from '@progress/kendo-react-buttons';
import React from 'react';
import { GearReportIssueState } from '../../../Model/GearReportIssueState';

interface ReportIssueBodyProps {
  reportIssueState: GearReportIssueState;
  className?: string;
}

const ReportIssueBody: React.FC<ReportIssueBodyProps> = ({ reportIssueState, className }) => {
  return (
    <div className={className || ''}>
      <Button
        themeColor="dark"
        onClick={() =>
          window.open(
            `mailto:${reportIssueState.emailReceipient || ''}?subject=${reportIssueState.emailSubject || ''}&body=${reportIssueState.emailBody || ''}`
          )
        }
      >
        {reportIssueState.buttonLabel}
      </Button>
      <br />
      <div
        dangerouslySetInnerHTML={{
          __html: reportIssueState.keyUsersContactsContent || ''
        }}
      />
    </div>
  );
};

export default ReportIssueBody;
