import * as React from 'react';
import { IntlHelper } from '../../../../../helpers/IntlHelper';
import { useLocalization } from '@progress/kendo-react-intl';
import { PrivateViewHeaderButtons } from './PrivateViewHeaderButtons';
import { useViewsContext } from '../../../../../context/view/ViewsContext';

export const PrivateViewHeader: React.FC = () => {
  const localization = useLocalization();
  const { privateViewState, privateViewLoading, privateView } = useViewsContext();
  return (
    <>
      <span>{IntlHelper.toLangStr(localization, 'custom.settings.views.private.title')}</span>
      {!(!privateViewLoading && !privateView && privateViewState?.data.length === 0) && <PrivateViewHeaderButtons />}
    </>
  );
};
