import * as React from 'react';
import { Typography } from '@progress/kendo-react-common';
import { IntlHelper } from '../../../helpers/IntlHelper';
import { useLocalization } from '@progress/kendo-react-intl';

export const CannotOpenFile: React.FC = () => {
  const localization = useLocalization();
  return (
    <div>
      <Typography.h2>
        {IntlHelper.toLangStr(localization, 'custom.settings.adminCenter.helpCenter.cannotOpenFileTitle')}
      </Typography.h2>
      <Typography.p>
        {IntlHelper.toLangStr(localization, 'custom.settings.adminCenter.helpCenter.cannotOpenFileMessage')}
      </Typography.p>
    </div>
  );
};
