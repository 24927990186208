import * as React from 'react';
import { MsGraphContext, MsGraphProps } from './MsGraphContext';
import { useGetDriveItemFromUrl } from './useGetDriveItemFromUrl';
import { useState } from 'react';

interface MsGraphProviderProps {
  children?: React.ReactNode;
}

export const MsGraphProvider: React.FC<MsGraphProviderProps> = ({ children }) => {
  const [msGraphProps, setMsGraphProps] = useState<Partial<MsGraphProps>>({});
  const { data: driveItem, isLoading: isDriveItemLoading } = useGetDriveItemFromUrl(msGraphProps?.fileUrl);

  return (
    <MsGraphContext.Provider
      value={{
        msGraphProps,
        setMsGraphProps,
        driveItem,
        isDriveItemLoading
      }}
    >
      {children}
    </MsGraphContext.Provider>
  );
};
