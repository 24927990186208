import React from 'react';
import { ViewsService } from '../../context/view/ViewService';
import { SearchService } from '../../context/search/SearchService';
import { DocumentsService } from '../../context/documents/DocumentsService';
import { FieldsService } from '../../context/fields/FieldsService';
import { LocalesService } from '../../context/globalization/LocalesService';
import { CurrentUserService } from '../../context/currentUser/CurrentUserService';
import { UiAdminConfigsService } from '../../context/uiAdminConfigs/UiAdminConfigsService';
import { MsGraphService } from '../../context/msGraph/MsGraphService';

export interface IServicesContext {
  currentUserService?: CurrentUserService;
  viewsService?: ViewsService;
  fieldsService?: FieldsService;
  searchService?: SearchService;
  documentsService?: DocumentsService;
  localesService?: LocalesService;
  uiAdminConfigsService?: UiAdminConfigsService;
  msGraphService?: MsGraphService;
}

const ServicesContext = React.createContext<IServicesContext>({});

export default ServicesContext;
