import { GEAR_USER_ALLOWED_ACTIONS, GearUserAllowedActions } from '../Model/enums/GearUserAllowedActions';
import { SearchFolder } from '../Model/SearchFolder';

export class DocumentsGridHelper {
  public static getSearchResultKey = (folder: SearchFolder, searchKey?: string) => {
    return `${folder?.id || ''}_${searchKey || ''}`;
  };

  public static getActionsFromFlag = (flag: number): GearUserAllowedActions[] => {
    const actions: GearUserAllowedActions[] = [];

    for (const action of Object.keys(GEAR_USER_ALLOWED_ACTIONS)) {
      const actionValue = GEAR_USER_ALLOWED_ACTIONS[action as keyof typeof GEAR_USER_ALLOWED_ACTIONS];
      if (typeof actionValue === 'number' && (flag & actionValue) === actionValue) {
        actions.push(actionValue);
      }
    }

    return actions;
  };
}
