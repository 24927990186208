import { KyResponse } from 'ky';
import { ExecuteEndpoint, ExecuteGetFile } from '../../components/common/BaseApiService';
import { LockUnlockDocumentsInfoRequestDto } from '../../Model/requests/LockUnlockDocumentsInfoRequestDto';
import { LockUnlockResponseDto } from '../../Model/responseDtos/LockUnlockResponseDto';

export const buildDocumentEndpointPath = (
  contentType: string,
  documentId: string,
  indexId?: number,
  displayInBrowser?: boolean
) =>
  `content-types/${contentType}/documents/${documentId}/content?indexId=${indexId}&displayInBrowser=${displayInBrowser}`;

export class DocumentsService {
  public async getDocumentContent(
    contentType: string,
    documentId: string,
    indexId?: number,
    displayInBrowser?: boolean
  ): Promise<KyResponse> {
    const endpoint = buildDocumentEndpointPath(contentType, documentId, indexId, displayInBrowser);
    return ExecuteGetFile(endpoint);
  }

  public async lockDocuments(dto: LockUnlockDocumentsInfoRequestDto): Promise<LockUnlockResponseDto> {
    return ExecuteEndpoint(`indexes/${dto.indexId}/documents/lock`, 'POST', { json: dto });
  }

  public async unlockDocuments(dto: LockUnlockDocumentsInfoRequestDto): Promise<LockUnlockResponseDto> {
    return ExecuteEndpoint(`indexes/${dto.indexId}/documents/unlock`, 'POST', { json: dto });
  }
}
