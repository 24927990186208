import { DriveItem } from '../../Model/responseDtos/graph/DriveItem';
import { ExecuteEndpoint } from '../../components/common/BaseApiService';
import { graphConfig } from '../../config/authConfig';

export class MsGraphService {
  // Encoding sharing URLs https://learn.microsoft.com/en-us/graph/api/shares-get?view=graph-rest-1.0&tabs=javascript#encoding-sharing-urls
  private static urlToSharingToken = (inputUrl: string): string => {
    let base64Value = btoa(unescape(encodeURIComponent(inputUrl)));
    base64Value = base64Value.replace(/=+$/, '').replace(/\//g, '_').replace(/\+/g, '-');
    return 'u!' + base64Value;
  };

  // https://learn.microsoft.com/en-us/graph/api/driveitem-get-content?view=graph-rest-1.0&tabs=http
  public async getDriveItem(fileUrl: string): Promise<DriveItem> {
    const shareIdOrEncodedSharingUrl = MsGraphService.urlToSharingToken(fileUrl);
    return ExecuteEndpoint(graphConfig.graphGetDriveItem(shareIdOrEncodedSharingUrl), 'GET');
  }
}
